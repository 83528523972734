import * as React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

import * as generalStyles from "../../scss/privacy.module.scss"
import * as styles from "../../scss/loginPage.module.scss"
import * as formStyles from "../../scss/form.module.scss"
import * as btnStyles from "../../components/buttons/buttons.module.scss"
import axios from "axios"
import { Link, navigate } from "@reach/router"
import Logo from "../../images/logo.svg"


const ResetPasswordPage = () => {
    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)
    const shapeThird = useRef(null)
    const [email, setEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showInvCred, setShowInvCred] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm()


    const doSubmit = ({ email }) => {

        let config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/passwordreset',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email
            }
        };

        axios(config)
            .then((response) => {
                console.log("The axios login response", response);
                if (response.data.c == 200) {
                    setShowSuccess(true);
                }
                else if (response.data.c == 403) {
                    setShowInvCred(true);
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });

    }



    return (

        <div className={styles.loginPage}>
            <div className={styles.shapeFirst}></div>
            <div className={styles.shapeSecond}></div>
            <div className="container">
                <Link to="/">
                    <Logo className={styles.logo} />
                </Link>
                <div className={styles.row}>
                    <div className={styles.content}>
                        <h1>Einsteigen & Entgiften</h1>
                        <p>
                            Mithilfe meines Online Detox-Kurses erfährst du die besten
                            Praktiken, wertvolle Tipps und Schritt-für-Schritt Anleitungen, um
                            deinen Körper effektiv von Giftstoffen zu befreien.
                        </p>
                        <p>
                            So ist es möglich, mit Genuss und Freude zu entgiften, sich von
                            körperlichen Beschwerden zu befreien und seine Gesundheit auch in
                            einer belastenden Umwelt zu erhalten.
                        </p>
                    </div>
                    <div className={styles.form}>
                        <h2>Passwort zurücksetzen</h2>

                        <form className={formStyles.form} onSubmit={handleSubmit(doSubmit)}>

                            <p style={{ marginBottom: " 5px" }}>E-Mail</p>
                            <input
                                type="email"
                                name="email"
                                className={formStyles.input}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                placeholder="mail@adresse.com"
                                ref={register({
                                    required: true,
                                    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                                })}
                            />
                            {errors.email &&
                                <p class={formStyles.inputError} style={{ marginTop: "5px" }}>E-Mailadresse wird benötigt</p>}

                            <input style={{ marginTop: " 25px" }}
                                type="submit"
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                                value="Passwort zurücksetzen"
                            />

                            {showInvCred && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Deine Emailadresse ist falsch.
                                </p>
                            )}
                            {showSuccess && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px", color: 'green' }}>
                                    Wir haben eine E-Mail an {email} geschickt. Bitte folge den Anweisungen. Falls sie nicht ankommt, schaue auch in deinen Spam-Ordner.
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage